<template>
    <div class="banner-content">
        <div class="left-panel">
            <h1 class="title">The First Monetized Cookie Consent Solution for Privacy Compliance</h1>
            <ul>
                <li><strong>Earn recurring revenue</strong> from your cookie consent banner</li>
                <li><strong>Stay compliant</strong> with ever-changing privacy laws</li>
                <li>Enhance and streamline <strong class="ml-5">user experience</strong></li>
                <li><strong>100% Free</strong> and Installs in just minutes</li>
            </ul>
            <a href="#request-invitation" class="btn btn-yellow"><strong>Book Demo</strong></a>
        </div>
        <div class="vcc-screenshot">
            <div class="ss-holder">
                <img src="~@/assets/images/vendor-and-cookie-1.png" alt="VCC">
            </div>
            <!-- <img src="~@/assets/images/vcc-web-screenshot.webp" alt="VCC" width="748" height="560"> -->
        </div>
    </div>
</template>

<script>

export default {
    name: 'HeroBanner'
}
</script>

<style scoped></style>